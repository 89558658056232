<template>
  <div class="container" v-cloak @mousemove="doMouseMove">
    <transition name="fade">
      <div class="text-box" v-show="persuadeInView">
        <div class="triangle" :style="{ height: height, width: width }"></div>

        <h2>TAKE YOUR IDEAS ONLINE!</h2>
        <p>{{ textData.text.text }}</p>
      </div>
    </transition>
    <div class="image-box">
      <iframe
        v-if="innerWidth < 500"
        src="/animated/animatehouseswindows_small.html"
        width="400"
        height="402"
        style="border: none"
      ></iframe>
      <iframe
        v-else-if="innerWidth > 500"
        src="/animated/animatehouseswindows_big.html"
        width="600"
        height="602"
        style="border: none"
      ></iframe>
    </div>
  </div>
</template>

<script>
import { reactive, computed, onMounted } from "vue";

import sanity from "../../client";

export default {
  name: "persuadeSection",
  components: {},
  props: ["persuadeInView"],
  setup() {
    const query = `*[_type == "persuade"]{text}`;

    onMounted(() => {
      fetchData();
    });
    const textData = reactive({
      showText: false,
      text: "",
    });

    const fetchData = async function () {
      try {
        const texts = await sanity.fetch(query);
        textData.text = texts[0];
      } catch (e) {
        console.log(e);
      }
    };

    const mouseData = reactive({
      posX: 0,
      posY: 0,
    });

    const innerWidth = computed(() => {
      return window.innerWidth;
    });

    const doMouseMove = (event) => {
      mouseData.posY = Math.ceil(event.clientY / 10);
      mouseData.posX = Math.ceil(event.clientX / 10);

      animateTriangle();
    };

    const animateTriangle = () => {
      const el = document.querySelector(".triangle");
      el.animate(
        [
          {},
          {
            transform: `translateX(${mouseData.posY * 4}px) rotate(${
              mouseData.posX * 1.3
            }deg)`,
          },
        ],
        {
          duration: 2000,
          delay: 100,
          fill: "forwards",
        }
      );
    };

    return {
      textData,
      innerWidth,
      width: "20rem",
      height: "20rem",
      animateTriangle,
      doMouseMove,
    };
  },
};
</script>

<style scoped lang="scss">
[v-cloak] {
  display: none;
}

.container {
  background: url("../../assets/images/PNG/Artboard 1.png"),
    var(--color-secondary);
  background-size: cover;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  @media screen and (min-width: 40em) {
    padding: 2rem;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 3fr 2fr;
    align-items: center;
  }
  @media screen and (min-width: 90em) {
    grid-template-columns: 2fr 2fr;
    justify-content: space-around;
  }
}

.triangle {
  position: absolute;
  top: 20%;
  left: 6rem;
  background-color: rgba(252, 252, 252, 0.2);

  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.text-box {
  color: var(--color-my-white);
  margin: 4rem 2rem;
  @media screen and (min-width: 40em) {
    //background-color: rgba(252, 252, 252, .1);
    margin-left: 2rem;
    grid-column: 1/2;
    grid-row: 1/2;
    padding: 2rem 6rem;

    p {
      font-size: 2rem;
    }
  }
  @media screen and (min-width: 90em) {
    p {
      font-size: 2.6rem;
    }
  }
}

.image-box {
  margin: 0 auto;
  @media screen and (min-width: 40em) {
    grid-column: 2/3;
  }

  iframe {
    border-radius: 3rem;
  }
}

.fade-leave-to,
.fade-enter-from {
  opacity: 0;
}

.fade-leave-active,
.fade-enter-active {
  transition: all 0.5s linear 1.5s;
}
</style>
